import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  BUYER_GENDER_KEY = 'BUYER_GENDER_KEY';
  BUYER_FIRSTNAME_KEY = 'BUYER_FIRSTNAME_KEY';
  BUYER_LASTNAME_KEY = 'BUYER_LASTNAME_KEY';
  BUYER_PHONE = 'BUYER_PHONE';
  BUYER_EMAIL = 'BUYER_EMAIL';

  get gender(): string {
    return sessionStorage.getItem(this.BUYER_GENDER_KEY);
  }
  set gender(value: string) {
    sessionStorage.setItem(this.BUYER_GENDER_KEY, value);
  }

  get firstname(): string {
    return sessionStorage.getItem(this.BUYER_FIRSTNAME_KEY);
  }
  set firstname(value: string) {
    sessionStorage.setItem(this.BUYER_FIRSTNAME_KEY, value);
  }

  get lastname(): string {
    return sessionStorage.getItem(this.BUYER_LASTNAME_KEY);
  }
  set lastname(value: string) {
    sessionStorage.setItem(this.BUYER_LASTNAME_KEY, value);
  }

  get fullname(): string {
    return `${this.lastname ?? ''} ${this.firstname ?? ''}`.trim();
  }

  get phone(): string {
    return sessionStorage.getItem(this.BUYER_PHONE);
  }
  set phone(value: string) {
    sessionStorage.setItem(this.BUYER_PHONE, value);
  }

  get email(): string {
    return sessionStorage.getItem(this.BUYER_EMAIL);
  }
  set email(value: string) {
    sessionStorage.setItem(this.BUYER_EMAIL, value);
  }

  clearData(): void {
    sessionStorage.removeItem(this.BUYER_GENDER_KEY);
    sessionStorage.removeItem(this.BUYER_FIRSTNAME_KEY);
    sessionStorage.removeItem(this.BUYER_LASTNAME_KEY);
    sessionStorage.removeItem(this.BUYER_PHONE);
    sessionStorage.removeItem(this.BUYER_EMAIL);
  }
}
