import { ActivatedRouteSnapshot, Resolve, ResolveFn, RouterStateSnapshot } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Observable, of } from "rxjs";
import { TranslatePmsLoader } from "@core/services/translate-pms-loader";
// import { Injectable } from "@angular/core";
import { UserService } from "@core/services/user.service";
import { inject } from "@angular/core";


// OLD Deprecated Resolver
// @Injectable()
// export class TranslateResolver implements Resolve<any> {

//   private currentLang: string;

//   constructor(
//     private translateService: TranslateService,
//     private userService: UserService,
//   ) { }

//   resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
//     const loader: TranslatePmsLoader = this.translateService.currentLoader as TranslatePmsLoader;
//     const translation = this.translateService.instant(loader.page);
//     const isAlreadyLoaded = translation[loader.page[0]] !== loader.page[0] && this.userService.lang === this.currentLang;

//     if (!isAlreadyLoaded && this.translateService.currentLang) {
//       this.currentLang = this.translateService.currentLang;
//       this.translateService.currentLang = '';
//       return this.translateService.use(this.currentLang);
//     } else {
//       return of(true);
//     }
//   }
// }

export const TranslateResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  translateService: TranslateService = inject(TranslateService),
  userService: UserService = inject(UserService),
): Observable<any> | Promise<any> | any => {

  let currentLang;
  const loader: TranslatePmsLoader = translateService.currentLoader as TranslatePmsLoader;
  const translation = translateService.instant(loader.page);
  const isAlreadyLoaded = translation[loader.page[0]] !== loader.page[0] && userService.lang === currentLang;

  if (!isAlreadyLoaded && translateService.currentLang) {
    currentLang = translateService.currentLang;
    translateService.currentLang = '';
    return translateService.use(currentLang);
  }

  return of(true);
}
