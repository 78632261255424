import { TERM_LINKS_CP, TERM_LINKS_PV, TERM_LINKS_SP } from "./terms/term-link.const";
import { FOOTER_SECURE_PAYMENT_CP, FOOTER_SECURE_PAYMENT_PV, FOOTER_LINKS_CP, FOOTER_LINKS_PV, FOOTER_LINKS_SP, FOOTER_SECURE_PAYMENT_SP } from "./footer/footer.const";
import { Theme } from "./theme.model";
import { PAYMENT_TERMS_CP, PAYMENT_TERMS_PV, PAYMENT_TERMS_SP } from "./payment/payment-term.const";
import { PHONE_NUMBER_CP, PHONE_NUMBER_PV, PHONE_NUMBER_SP } from "./phone/phone-number.const";

export const THEMES: Theme[] = [
  {
    host: [
      'default' // Default theme
    ],
    name: 'theme-pv',
    brand: 'pv',
    brandName: 'Pierre & Vacances',
    brandNameShort: 'P&V',
    logo: 'assets/img/pv/logo-pv.png',
    logoAlt: 'assets/img/pv/logo-pv-alt.png',
    card: '/assets/img/card/pv_bank_card.png',
    smallCard: '/assets/img/card/pv_bank_card_small.png',
    appFavicon: 'assets/img/pv/favicon.png',
    appTitle: 'Pierre & Vacances - Pre-Checkin',
    footerLinks: FOOTER_LINKS_CP,
    footerSecurePaymentIcons: FOOTER_SECURE_PAYMENT_PV,
    applauseIcon: '/assets/img/pv/applause.png',
    termLinks: TERM_LINKS_CP,
    paymentTermLinks: {},
    phoneNumber: {},
    privacyCenterScript1: "",
    privacyCenterScript2: "",
  },
  {
    host: [
      // 'localhost',
      'gift-onsite360-dev.groupepvcp.com', // dev
      'gift-onsite360-test.groupepvcp.com', // rct
      'gift.pierreetvacances.com', // prod
      'idee-cadeau.pierreetvacances.com'
    ],
    partialHost: 'pierreetvacances',
    name: 'theme-pv',
    brand: 'pv',
    brandName: 'Pierre & Vacances',
    brandNameShort: 'P&V',
    logo: 'assets/img/pv/logo-pv.png',
    logoAlt: 'assets/img/pv/logo-pv-alt.png',
    logoMobile: 'icon-pv-mobile-logo',
    card: '/assets/img/card/pv_bank_card.png',
    smallCard: '/assets/img/card/pv_bank_card_small.png',
    appFavicon: 'assets/img/pv/favicon.png',
    appTitle: 'Pierre & Vacances - Gift Voucher',
    footerLinks: FOOTER_LINKS_PV,
    footerSecurePaymentIcons: FOOTER_SECURE_PAYMENT_PV,
    applauseIcon: '/assets/img/pv/applause.png',
    termLinks: TERM_LINKS_PV,
    paymentTermLinks: PAYMENT_TERMS_PV,
    phoneNumber: PHONE_NUMBER_PV,
    privacyCenterScript1: `(function(){function i(e){if(!window.frames[e]){if(document.body&&document.body.firstChild){var t=document.body;var n=document.createElement("iframe");n.style.display="none";n.name=e;n.title=e;t.insertBefore(n,t.firstChild)}else{setTimeout(function(){i(e)},5)}}}function e(n,o,r,f,s){function e(e,t,n,i){if(typeof n!=="function"){return}if(!window[o]){window[o]=[]}var a=false;if(s){a=s(e,i,n)}if(!a){window[o].push({command:e,version:t,callback:n,parameter:i})}}e.stub=true;e.stubVersion=2;function t(i){if(!window[n]||window[n].stub!==true){return}if(!i.data){return}var a=typeof i.data==="string";var e;try{e=a?JSON.parse(i.data):i.data}catch(t){return}if(e[r]){var o=e[r];window[n](o.command,o.version,function(e,t){var n={};n[f]={returnValue:e,success:t,callId:o.callId};if(i.source){i.source.postMessage(a?JSON.stringify(n):n,"*")}},o.parameter)}}if(typeof window[n]!=="function"){window[n]=e;if(window.addEventListener){window.addEventListener("message",t,false)}else{window.attachEvent("onmessage",t)}}}e("__tcfapi","__tcfapiBuffer","__tcfapiCall","__tcfapiReturn");i("__tcfapiLocator")})();`,
    privacyCenterScript2: `(function(){(function(e){var r=document.createElement("link");r.rel="preconnect";r.as="script";var t=document.createElement("link");t.rel="dns-prefetch";t.as="script";var n=document.createElement("script");n.id="spcloader";n.type="text/javascript";n["async"]=true;n.charset="utf-8";var o="https://sdk.privacy-center.org/"+e+"/loader.js?target="+document.location.hostname;if(window.didomiConfig&&window.didomiConfig.user){var i=window.didomiConfig.user;var a=i.country;var c=i.region;if(a){o=o+"&country="+a;if(c){o=o+"&region="+c}}}r.href="https://sdk.privacy-center.org/";t.href="https://sdk.privacy-center.org/";n.src=o;var d=document.getElementsByTagName("script")[0];d.parentNode.insertBefore(r,d);d.parentNode.insertBefore(t,d);d.parentNode.insertBefore(n,d)})("cfa6ae3b-45ec-421c-b120-fbf942ba08b8")})();`,
  },
  {
    host: [
      'localhost',
      'cp-gift-onsite360-dev.groupepvcp.com',
      'cp-gift-onsite360-test.groupepvcp.com',
      'gift.centerparcs.com', // prod
      'chequecadeau.centerparcs.fr',
      'geschenkgutsch.centerparcs.de',
      'cadeaubon.centerparcs.nl',
      'cadeaubon.centerparcs.be',
      'chequecadeau.centerparcs.be',
      'giftvoucher.centerparcs.eu',
    ],
    partialHost: 'centerparcs',
    name: 'theme-cp',
    brand: 'cp',
    brandName: 'Center Parcs',
    brandNameShort: 'CP',
    logo: 'assets/img/cp/logo-cp.png',
    logoAlt: 'assets/img/cp/logo-cp.png',
    logoMobile: 'icon-cp-mobile-logo',
    card: '/assets/img/card/cp_bank_card.png',
    smallCard: '/assets/img/card/cp_bank_card_small.png',
    appFavicon: 'assets/img/cp/favicon.png',
    appTitle: 'Center Parcs - Gift Voucher',
    footerLinks: FOOTER_LINKS_CP,
    termLinks: TERM_LINKS_CP,
    footerSecurePaymentIcons: FOOTER_SECURE_PAYMENT_CP,
    applauseIcon: '/assets/img/cp/applause.png',
    paymentTermLinks: PAYMENT_TERMS_CP,
    phoneNumber: PHONE_NUMBER_CP,
    privacyCenterScript1: `(function(){function i(e){if(!window.frames[e]){if(document.body&&document.body.firstChild){var t=document.body;var n=document.createElement("iframe");n.style.display="none";n.name=e;n.title=e;t.insertBefore(n,t.firstChild)}else{setTimeout(function(){i(e)},5)}}}function e(n,o,r,f,s){function e(e,t,n,i){if(typeof n!=="function"){return}if(!window[o]){window[o]=[]}var a=false;if(s){a=s(e,i,n)}if(!a){window[o].push({command:e,version:t,callback:n,parameter:i})}}e.stub=true;e.stubVersion=2;function t(i){if(!window[n]||window[n].stub!==true){return}if(!i.data){return}var a=typeof i.data==="string";var e;try{e=a?JSON.parse(i.data):i.data}catch(t){return}if(e[r]){var o=e[r];window[n](o.command,o.version,function(e,t){var n={};n[f]={returnValue:e,success:t,callId:o.callId};if(i.source){i.source.postMessage(a?JSON.stringify(n):n,"*")}},o.parameter)}}if(typeof window[n]!=="function"){window[n]=e;if(window.addEventListener){window.addEventListener("message",t,false)}else{window.attachEvent("onmessage",t)}}}e("__tcfapi","__tcfapiBuffer","__tcfapiCall","__tcfapiReturn");i("__tcfapiLocator")})();`,
    privacyCenterScript2: `(function(){(function(e,r){var t=document.createElement("link");t.rel="preconnect";t.as="script";var n=document.createElement("link");n.rel="dns-prefetch";n.as="script";var i=document.createElement("script");i.id="spcloader";i.type="text/javascript";i["async"]=true;i.charset="utf-8";var o="https://sdk.privacy-center.org/"+e+"/loader.js?target_type=notice&target="+r;if(window.didomiConfig&&window.didomiConfig.user){var a=window.didomiConfig.user;var c=a.country;var d=a.region;if(c){o=o+"&country="+c;if(d){o=o+"&region="+d}}}t.href="https://sdk.privacy-center.org/";n.href="https://sdk.privacy-center.org/";i.src=o;var s=document.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s);s.parentNode.insertBefore(n,s);s.parentNode.insertBefore(i,s)})("cfa6ae3b-45ec-421c-b120-fbf942ba08b8","89PReskW")})();`
  },
  {
    host: [
      // 'localhost',
      'sp-gift-onsite360-dev.groupepvcp.com',
      'sp-gift-onsite360-test.groupepvcp.com',
      'cadeaubon.sunparks.com',
      'chequecadeau.sunparks.com',
      'chequecadeau.sunparks.com',
      'geschenkgutsch.sunparks.com',
      'cadeaubon.sunparks.com',
      'giftvoucher.sunparks.com'
    ],
    partialHost: 'sunparks',
    name: 'theme-sp',
    brand: 'sp',
    brandName: 'Sunparks',
    brandNameShort: 'SP',
    logo: 'assets/img/sp/logo-sp.png',
    logoAlt: 'assets/img/sp/logo-sp.png',
    logoMobile: 'icon-sp-mobile-logo',
    card: '/assets/img/card/sp_bank_card.png',
    smallCard: '/assets/img/card/sp_bank_card_small.png',
    appFavicon: 'assets/img/sp/favicon.png',
    appTitle: 'Sunparks - Gift Voucher',
    footerLinks: FOOTER_LINKS_SP,
    termLinks: TERM_LINKS_SP,
    footerSecurePaymentIcons: FOOTER_SECURE_PAYMENT_SP,
    applauseIcon: '/assets/img/cp/applause.png',
    paymentTermLinks: PAYMENT_TERMS_SP,
    phoneNumber: PHONE_NUMBER_SP,
    privacyCenterScript1: `(function(){function i(e){if(!window.frames[e]){if(document.body&&document.body.firstChild){var t=document.body;var n=document.createElement("iframe");n.style.display="none";n.name=e;n.title=e;t.insertBefore(n,t.firstChild)}else{setTimeout(function(){i(e)},5)}}}function e(n,o,r,f,s){function e(e,t,n,i){if(typeof n!=="function"){return}if(!window[o]){window[o]=[]}var a=false;if(s){a=s(e,i,n)}if(!a){window[o].push({command:e,version:t,callback:n,parameter:i})}}e.stub=true;e.stubVersion=2;function t(i){if(!window[n]||window[n].stub!==true){return}if(!i.data){return}var a=typeof i.data==="string";var e;try{e=a?JSON.parse(i.data):i.data}catch(t){return}if(e[r]){var o=e[r];window[n](o.command,o.version,function(e,t){var n={};n[f]={returnValue:e,success:t,callId:o.callId};if(i.source){i.source.postMessage(a?JSON.stringify(n):n,"*")}},o.parameter)}}if(typeof window[n]!=="function"){window[n]=e;if(window.addEventListener){window.addEventListener("message",t,false)}else{window.attachEvent("onmessage",t)}}}e("__tcfapi","__tcfapiBuffer","__tcfapiCall","__tcfapiReturn");i("__tcfapiLocator")})();`,
    privacyCenterScript2: `(function(){(function(e){var r=document.createElement("link");r.rel="preconnect";r.as="script";var t=document.createElement("link");t.rel="dns-prefetch";t.as="script";var n=document.createElement("script");n.id="spcloader";n.type="text/javascript";n["async"]=true;n.charset="utf-8";var o="https://sdk.privacy-center.org/"+e+"/loader.js?target="+document.location.hostname;if(window.didomiConfig&&window.didomiConfig.user){var i=window.didomiConfig.user;var a=i.country;var c=i.region;if(a){o=o+"&country="+a;if(c){o=o+"&region="+c}}}r.href="https://sdk.privacy-center.org/";t.href="https://sdk.privacy-center.org/";n.src=o;var d=document.getElementsByTagName("script")[0];d.parentNode.insertBefore(r,d);d.parentNode.insertBefore(t,d);d.parentNode.insertBefore(n,d)})("cfa6ae3b-45ec-421c-b120-fbf942ba08b8")})();`
  },
]
