import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ContactService } from '@core/services/contact.service';
import { GiftVoucherService } from '@core/services/gift-voucher.service';
import { StepRouteService } from '@core/services/step-route.service';
import { Theme } from '@core/services/theme/theme.model';
import { ThemeService } from '@core/services/theme/theme.service';
import { UserService } from '@core/services/user.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-bank-card-canvas',
  templateUrl: './bank-card-canvas.component.html',
  styleUrls: ['./bank-card-canvas.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class BankCardCanvasComponent implements OnInit {
  @ViewChild('overlay', { read: ElementRef }) overlay?: ElementRef;

  theme: Theme = null;
  isOpen = false;

  constructor(
    private giftVoucherService: GiftVoucherService,
    private themeService: ThemeService,
    private contactService: ContactService,
    private userService: UserService,
    public stepRouteService: StepRouteService,
  ) {}

  ngOnInit(): void {
    this.getThemeInfos();
  }

  getThemeInfos(): void {
    this.theme = this.themeService.theme;
  }

  get voucherValue(): number {
    return this.giftVoucherService.voucherValue;
  }

  get voucherQuantity(): number {
    return this.giftVoucherService.voucherQuantity;
  }

  get hasReceiver(): boolean {
    return this.giftVoucherService.hasReceiver;
  }

  get buyernName(): string {
    return this.contactService.fullname;
  }

  get receiverName(): string {
    return this.giftVoucherService.receiverName;
  }

  get receiverEmail(): string {
    return this.giftVoucherService.receiverEmail;
  }

  get receiveDate(): Date {
    return this.giftVoucherService.receiveDate;
  }

  get buyerName(): string {
    return this.contactService.fullname;
  }

  get buyerFirstname(): string {
    return this.contactService.firstname;
  }

  get buyerLastname(): string {
    return this.contactService.lastname;
  }

  get buyerEmail(): string {
    return this.contactService.email;
  }

  get hasBuyerInfo(): boolean {
    return !!this.buyerFirstname || !!this.buyerLastname || !!this.buyerEmail;
  }

  get locale(): string {
    return this.userService.locale;
  }

  toggle(): void {
    this.isOpen = !this.isOpen;
  }

  handleWrapperClick(event: Event) {
    event.stopPropagation();
  }
}
