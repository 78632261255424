import { Component } from '@angular/core';

@Component({
  selector: 'app-layout-symbols',
  templateUrl: './layout-symbols.component.html',
})
export class LayoutSymbolsComponent {

  constructor() { }

}
