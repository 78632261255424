import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactSettingComponent } from './contact-setting.component';
import { Routes, RouterModule } from '@angular/router';
import { TranslateResolver } from '@core/resolver/translate.resolver';
import { HttpClient } from '@angular/common/http';
import { TranslatePmsLoader } from '@core/services/translate-pms-loader';
import { environment } from '@env/environment';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { ReactiveFormsModule } from '@angular/forms';
import { CommercialFormComponent } from './commerial-form/commercial-form.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslatePmsLoader(
    http,
    ['SCREEN_GIFTVOUCHER_CONTACT_SETTING'],
    environment.api.urlWording,
  );
}

const routes: Routes = [
  {
    path: '',
    component: ContactSettingComponent,
    resolve: { translate: TranslateResolver },
  },
];

@NgModule({
  declarations: [ContactSettingComponent, CommercialFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      extend: true,
    }),

    MatButtonToggleModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
  ],
  exports: [CommercialFormComponent]
})
export class ContactSettingModule {}
