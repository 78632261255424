export const PHONE_NUMBER_CP = {
  'be-vl': 'by_phone_cp_be',
  'be-wl': 'by_phone_cp_be'
};

export const PHONE_NUMBER_SP = {
  'be-vl': 'by_phone_sp_be',
  'be-wl': 'by_phone_sp_be'
};

export const PHONE_NUMBER_PV = {};
