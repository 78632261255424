<ng-container>
  <div class="sticky-navbar-container position-sticky d-flex d-md-none align-items-center justify-content-between">
    <div class="left-content">
      <h2 *ngIf="voucherAmount" class="m-0">{{ voucherAmount | currency:gvCurrency:'symbol':'0.0-0':locale }}</h2>
      <a class="view-details m-0" (click)="openBankCard()">{{ !bankCardCanvas.isOpen ? ('SCREEN_GIFTVOUCHER_COMMON.view_detail' | translate) : ('SCREEN_GIFTVOUCHER_COMMON.close_detail' | translate) }}</a>
    </div>
    <button [ngClass]="{'sp-btn': theme.brand === 'sp'}" class="btn-gift-voucher-continue border-0" (click)="goToNextStep()">
      {{ (!stepRouteService.isPaymentScreen ? 'SCREEN_GIFTVOUCHER_COMMON.continue' : 'SCREEN_GIFTVOUCHER_COMMON.pay') | translate }}
      {{ stepRouteService.isPaymentScreen ? (": " + (voucherAmount | currency:gvCurrency:'symbol':'0.0-0':locale )) : ''}}
    </button>
  </div>
</ng-container>

<ng-container>
  <app-bank-card-canvas #bankCardCanvas></app-bank-card-canvas>
</ng-container>
