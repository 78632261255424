import {TranslateLoader} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

export class TranslatePmsLoader implements TranslateLoader {

  constructor(
    private http: HttpClient, 
    public page: string[], 
    private baseurl: string
  ) {}

  getTranslation(lang: string): Observable<any> {
    const pages = this.page.join(',');
    return this.http.get(`${this.baseurl}/pages/wordings/${pages}/${lang}`).pipe(
      map((data: any) => {
        return data.data.results;
      })
    );
  }
}
