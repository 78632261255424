import { Link } from "@shared/models/link";

export const PAYMENT_TERMS_PV: { [key: string]: Link[] } = {
  "fr-fr": [
    {
      label: 'SCREEN_GIFTVOUCHER_COMMON.terms_and_conditions_pv',
      href: 'SCREEN_GIFTVOUCHER_COMMON.terms_and_conditions_pv_link'
    },
  ],
  "in-en": [
    {
      label: 'SCREEN_GIFTVOUCHER_COMMON.terms_and_conditions_pv',
      href: 'SCREEN_GIFTVOUCHER_COMMON.terms_and_conditions_pv_link'
    },
  ]
}

export const PAYMENT_TERMS_CP: { [key: string]: Link[] } = {
  "fr-fr": [
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.terms_and_conditions_cp",
      href: "SCREEN_GIFTVOUCHER_COMMON.terms_and_conditions_cp_link"
    }
  ],
  "in-en": [
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.terms_and_conditions_cp",
      href: "SCREEN_GIFTVOUCHER_COMMON.terms_and_conditions_cp_link"
    }
  ],
  "de-de": [
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.terms_and_conditions_cp",
      href: "SCREEN_GIFTVOUCHER_COMMON.terms_and_conditions_cp_link"
    }
  ],
  "nl-nl": [
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.terms_and_conditions_cp",
      href: "SCREEN_GIFTVOUCHER_COMMON.terms_and_conditions_cp_link"
    }
  ],
  "be-vl": [
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.terms_and_conditions_cp_vl",
      href: "SCREEN_GIFTVOUCHER_COMMON.terms_and_conditions_cp_vl_link"
    }
  ],
  "be-wl": [
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.terms_and_conditions_cp_wl",
      href: "SCREEN_GIFTVOUCHER_COMMON.terms_and_conditions_cp_wl_link"
    }
  ],
}

export const PAYMENT_TERMS_SP: { [key: string]: Link[] } = {
  "fr-fr": [
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.terms_and_conditions_sp",
      href: "SCREEN_GIFTVOUCHER_COMMON.terms_and_conditions_sp_link"
    }
  ],
  "in-en": [
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.terms_and_conditions_sp",
      href: "SCREEN_GIFTVOUCHER_COMMON.terms_and_conditions_sp_link"
    }
  ],
  "de-de": [
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.terms_and_conditions_sp",
      href: "SCREEN_GIFTVOUCHER_COMMON.terms_and_conditions_sp_link"
    }
  ],
  "nl-nl": [
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.terms_and_conditions_sp",
      href: "SCREEN_GIFTVOUCHER_COMMON.terms_and_conditions_sp_link"
    }
  ],
  "be-vl": [
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.terms_and_conditions_sp_vl",
      href: "SCREEN_GIFTVOUCHER_COMMON.terms_and_conditions_sp_vl_link"
    }
  ],
  "be-wl": [
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.terms_and_conditions_sp_wl",
      href: "SCREEN_GIFTVOUCHER_COMMON.terms_and_conditions_sp_wl_link"
    }
  ],
};
