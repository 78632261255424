import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackbarComponent } from './snackbar.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    SnackbarComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatSnackBarModule,
  ],
})
export class SnackbarModule { }
