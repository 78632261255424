import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { LoaderService } from '@core/services/loader.service';
import { ThemeService } from '@core/services/theme/theme.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent implements OnInit, OnDestroy {

  stop$: Subject<void> = new Subject<void>();
  isLoading = false;

  constructor(
    private loaderService: LoaderService,
    private ref: ChangeDetectorRef,
    public themeService: ThemeService,
  ) { }

  ngOnInit(): void {
    this.watchLoadingStatus();
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }

  watchLoadingStatus(): void {
    this.loaderService.loaderSubject$.pipe(
      takeUntil(this.stop$)
    ).subscribe({
      next: (isLoading: boolean) => {
        this.isLoading = isLoading;
        this.ref.detectChanges();
      },
      error: () => {
        this.isLoading = false;
      }
    });
  }
}
