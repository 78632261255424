import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HOSTNAME_DEFAULT_MARKET_LANG } from '@core/consts/hostname-default-market-lang';
import { StepRouteEnum } from '@core/enums/step-route.enum';
import { LayoutComponent } from '@core/modules/layout/layout.component';

const hostname = window.location.hostname;
const defaultMarketLang = HOSTNAME_DEFAULT_MARKET_LANG[hostname] ?? 'in-en';

const appRoutes: Routes = [
  {
    path: '',
    children: [
      { path: '', redirectTo: `${defaultMarketLang}/${StepRouteEnum.Home}`, pathMatch: 'full' },
      {
        path: ':market',
        loadChildren: () => import('@core/modules/layout/layout.module').then(m => m.LayoutModule)
      },
    ],
  },
  {
    path: '**',
    component: LayoutComponent ,
    loadChildren: () => import('@modules/app-pages/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
