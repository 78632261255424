import { Injectable, isDevMode } from '@angular/core';
import {HttpInterceptor, HttpHandler, HttpRequest} 
from '@angular/common/http';


@Injectable()
export class EnsureHttpsInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (!isDevMode()) {
        const secureReq = req.clone({
        url: req.url.replace('http://', 'https://')
      });
      return next.handle(secureReq);
    }
    return next.handle(req);
  }

}