import { Link } from '@shared/models/link';

// There are 3 links in term text content : /contact-setting
export const TERM_LINKS_CP: { [key: string]: Link[] } = {
  "fr-fr": [
    {
      label: "",
      href: "https://www.centerparcs.fr/fr-fr/contact_ms"
    },
    {
      label: "",
      href: "https://www.centerparcs.fr/fr-fr/politique-protection-donnees-cookies_ms"
    },
  ],
  "in-en": [
    {
      label: "",
      href: "https://www.centerparcs.eu/in-en/contact_ms"
    },
    {
      label: "",
      href: "https://www.centerparcs.eu/in-en/cookies-and-data-protection-policy_ms"
    },
  ],
  "de-de": [
    {
      label: "",
      href: "https://www.centerparcs.de/de-de/kontakt_ms"
    },
    {
      label: "",
      href: "https://www.centerparcs.de/de-de/allgemeine-geschaftsbedingungen_ms"
    },
  ],
  "nl-nl": [
    {
      label: "",
      href: "https://www.centerparcs.nl/nl-nl/contact_ms"
    },
    {
      label: "",
      href: "https://www.centerparcs.nl/nl-nl/beleid-persoonsgegevensbescherming-cookies_ms"
    },
  ],
  "be-vl": [
    {
      label: "",
      href: "https://www.centerparcs.be/be-vl/contact_ms"
    },
    {
      label: "",
      href: "https://www.centerparcs.be/be-vl/beleid-persoonsgegevensbescherming-cookies_ms"
    },
  ],
  "be-wl": [
    {
      label: "",
      href: "https://www.centerparcs.be/be-wl/contact_ms"
    },
    {
      label: "",
      href: "https://www.centerparcs.be/be-wl/politique-protection-donnees-cookies_ms"
    },
  ],
};

export const TERM_LINKS_SP: { [key: string]: Link[] } = {
  "fr-fr": [
    {
      label: "",
      href: "https://www.sunparks.com/fr-fr/contact_ms"
    },
    {
      label: "",
      href: "https://www.sunparks.com/fr-fr/data-protection_ms"
    },
  ],
  "in-en": [
    {
      label: "",
      href: "https://www.sunparks.com/in-en/contact_ms"
    },
    {
      label: "",
      href: "https://www.sunparks.com/in-en/data-protection_ms"
    },
  ],
  "de-de": [
    {
      label: "",
      href: "https://www.sunparks.com/de-de/kontakt_ms"
    },
    {
      label: "",
      href: "https://www.sunparks.com/de-de/datenschutz_ms"
    },
  ],
  "nl-nl": [
    {
      label: "",
      href: "https://www.sunparks.com/nl-nl/contact_ms"
    },
    {
      label: "",
      href: "https://www.sunparks.com/nl-nl/beleid-persoonsgegevensbescherming-cookies_ms"
    },
  ],
  "be-vl": [
    {
      label: "",
      href: "https://www.sunparks.com/be-vl/contact_ms"
    },
    {
      label: "",
      href: "https://www.sunparks.com/be-vl/beleid-persoonsgegevensbescherming-cookies_ms"
    },
  ],
  "be-wl": [
    {
      label: "",
      href: "https://www.sunparks.com/be-wl/contact_ms"
    },
    {
      label: "",
      href: "https://www.sunparks.com/be-wl/data-protection_ms"
    },
  ],
};


export const TERM_LINKS_PV: { [key: string]: Link[] } = {
  "fr-fr": [
    {
      label: '',
      href: 'https://www.pierreetvacances.com/fr-fr/mentions_ms?pl=footer'
    },
    {
      label: '',
      href: 'https://www.pierreetvacances.com/fr-fr/mentions_ms?pl=footer'
    },
  ],
  "in-en": [
    {
      label: '',
      href: 'https://www.pierreetvacances.com/GB-EN/disclaimer_ms'
    },
    {
      label: '',
      href: 'https://www.pierreetvacances.com/GB-EN/cookies-and-data-protection-policy_ms'
    },
  ]
};

