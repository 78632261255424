<div *ngIf="isLoading" class="loader-container">
  <div class="loader-layer"></div>
  <div class="loader-spinner">
    <svg class="cp-spin-icon" *ngIf="themeService.theme.brand === 'cp'"><use href="#spinner2" /></svg>
    <svg class="pv-spin-icon" *ngIf="themeService.theme.brand === 'pv'"><use href="#spinner" /></svg>
  </div>
</div>

<!-- On page refresh, layout-symbols component isn't fully charged, so we use symbol directly here -->
<svg style="display: none">
  <symbol id="spinner" viewBox="0 0 40 40">
    <path fill="currentColor" d="M9.601 28.4c-1 0-1.85.35-2.55 1.05C6.35 30.15 6 31 6 32c0 1 .35 1.85 1.05 2.55.7.7 1.55 1.05 2.55 1.05.983 0 1.83-.35 2.538-1.05.708-.7 1.062-1.55 1.062-2.55 0-1-.354-1.85-1.062-2.55-.709-.7-1.555-1.05-2.538-1.05ZM8.8 20.8c0-1.1-.391-2.041-1.175-2.825C6.842 17.19 5.9 16.8 4.8 16.8s-2.042.392-2.825 1.175C1.192 18.758.8 19.7.8 20.8s.392 2.041 1.175 2.825C2.758 24.408 3.7 24.8 4.8 24.8s2.042-.392 2.825-1.175C8.41 22.842 8.8 21.9 8.8 20.8ZM31.999 11.6c.55 0 1.02-.196 1.412-.588.392-.391.588-.862.588-1.412 0-.55-.196-1.021-.588-1.413a1.925 1.925 0 0 0-1.412-.587c-.55 0-1.021.196-1.413.587a1.926 1.926 0 0 0-.587 1.413c0 .55.195 1.02.587 1.412.392.392.863.588 1.413.588ZM9.6 5.2c-1.216 0-2.254.43-3.112 1.288C5.629 7.346 5.2 8.383 5.2 9.6s.43 2.254 1.288 3.113C7.347 13.57 8.384 14 9.6 14c1.216 0 2.254-.43 3.112-1.287C13.57 11.854 14 10.817 14 9.6s-.429-2.254-1.287-3.112C11.855 5.629 10.817 5.2 9.6 5.2ZM38.5 19.1a2.313 2.313 0 0 0-1.7-.7c-.667 0-1.234.233-1.7.7a2.316 2.316 0 0 0-.7 1.7c0 .666.233 1.234.7 1.7.466.466 1.033.7 1.7.7.666 0 1.233-.234 1.7-.7.466-.467.7-1.034.7-1.7 0-.667-.233-1.234-.7-1.7ZM32 29.2c-.767 0-1.426.274-1.976.824A2.695 2.695 0 0 0 29.2 32c0 .766.276 1.425.825 1.975.55.55 1.209.825 1.976.825.766 0 1.425-.275 1.975-.825.55-.55.825-1.208.825-1.975 0-.767-.275-1.425-.825-1.976A2.693 2.693 0 0 0 32 29.2ZM20.8 33.599c-.883 0-1.638.313-2.263.938A3.084 3.084 0 0 0 17.6 36.8c0 .883.312 1.637.937 2.262S19.917 40 20.8 40s1.637-.313 2.262-.938A3.082 3.082 0 0 0 24 36.8c0-.884-.313-1.638-.938-2.263A3.083 3.083 0 0 0 20.8 33.6ZM20.8 0c-1.333 0-2.467.466-3.4 1.4-.933.933-1.4 2.067-1.4 3.4 0 1.333.466 2.466 1.4 3.4.933.933 2.067 1.4 3.4 1.4 1.334 0 2.467-.467 3.4-1.4.933-.933 1.4-2.067 1.4-3.4 0-1.333-.467-2.466-1.4-3.4C23.267.467 22.134 0 20.8 0Z"/>
  </symbol>
  <symbol id="spinner2" width="60" height="53" fill="none">
    <path fill="currentColor" d="M19.427 38.47c-7.19-1.276-12.52-7.806-12.52-15.51 0-7.706 5.33-14.236 12.52-15.512a.26.26 0 0 0 .214-.26V.438c0-.16-.133-.286-.286-.265C14.19.812 9.419 3.349 5.849 7.377 2.08 11.635 0 17.167 0 22.962c0 5.794 2.078 11.326 5.85 15.585 3.564 4.025 8.33 6.56 13.502 7.204a.263.263 0 0 0 .286-.265v-6.75a.26.26 0 0 0-.213-.26l.002-.005Z"/>
    <path fill="currentColor" d="M37.857.003H26.801a.258.258 0 0 0-.253.264v26.188c0 .142-.106.26-.241.265h-.022a.253.253 0 0 1-.259-.202c-1.476-6.216-3.876-8.76-8.218-8.76h-2.517c-.16 0-.281.154-.248.32l2.048 7.738a.258.258 0 0 0 .248.212h.995c5.802 0 8.214 2.123 8.214 7.83v11.508c-.003.189-.005.39-.01.595-.033 1.326-.17 4.58-.765 6.704a.261.261 0 0 0 .246.335h7.967c.168 0 .291-.168.246-.335-.547-1.945-.707-4.835-.755-6.33l-.02-1.247V33.846c.003-5.7 2.417-7.82 8.214-7.82h.995c.12 0 .226-.09.248-.213l2.048-7.738c.033-.165-.087-.32-.248-.32h-2.517c-4.342 0-6.742 2.544-8.218 8.76a.255.255 0 0 1-.258.203h-.023a.262.262 0 0 1-.24-.265V7.487c0-.147.112-.264.253-.264h4.149c8.396 0 15.23 7.057 15.235 15.729 0 4.206-1.564 8.16-4.407 11.135-2.239 2.343-5.032 3.85-8.113 4.39a.26.26 0 0 0-.213.259v6.748c0 .16.133.286.286.267 4.868-.602 9.431-2.909 12.924-6.562C57.72 34.85 60.005 29.081 60 22.946 59.995 10.294 50.062 0 37.86 0l-.003.003Z"/>
  </symbol>
</svg>
