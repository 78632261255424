import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { User } from '../models/user.model';
import { DateAdapter } from '@angular/material/core';
import { DefaultMarketLanguagesEnum } from '@core/enums/default-market-languages.enum';
// import { JwtHelperService } from '@auth0/angular-jwt';

const GV_USER = 'giftvoucher_user';
const GV_LANG = 'giftvoucher_lang';
const GV_MARKET = 'giftvoucher_market';

const LANG_API_MAPPING: { [key: string]: string } = {
  en: 'en',
  fr: 'fr',
  wl: 'fr',
  de: 'de',
  nl: 'nl',
  vl: 'nl',
  it: 'it',
  es: 'es',
} as const;

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _user: User;
  // private _market: UserMarket;
  private _marketLang: string;
  private _market: string;
  private _lang: string;

  private _langChangeSubject: Subject<string> = new Subject<string>();
  private _marketChangeSubject: Subject<string> = new Subject<string>();
  langChange$ = this._langChangeSubject.asObservable();
  marketChange$ = this._marketChangeSubject.asObservable();

  redirectUrl: string;
  redirectBookingNumber: string;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private adapter: DateAdapter<any>,
  ) { }

  // Summary
    // get user() => private _user
    // getToken()

    // authLogin()
    // authAutoLogin()
    // authLogout()

    // login()
    // logout()
    // checkTokenValidity()
    // refreshToken()

  get user(): User {
    if (!this._user && localStorage.getItem(GV_USER)) {
      let user = JSON.parse(localStorage.getItem(GV_USER) as string) as User;
      this._user = Object.assign(new User(), user);
    }
    return this._user;
  }

  get lang(): string {
    if(!this._lang && localStorage.getItem(GV_LANG)) {
      const userLang = localStorage.getItem(GV_LANG);
      this._lang = userLang;
    }
    return LANG_API_MAPPING[this._lang];
  }

  get market(): string {
    if(!this._market && localStorage.getItem(GV_MARKET)) {
      const userMarket = localStorage.getItem(GV_MARKET);
      this._market = userMarket;
    }
    return this._market;
  }

  getToken(): string {
    return this.user?.token;
  }

  get locale(): string {
    if (this.market === 'fr' || this.market === 'de') {
      return 'fr';
    }
    return 'en';
  }

  get marketLang(): string {
    return this._marketLang;
  }

  ///////////////////////////////////////////////////////////////////////
  // APIs
  ///////////////////////////////////////////////////////////////////////

  // authLogin()
  // authAutoLogin()
  // authLogout()
  // refreshToken()

  ///////////////////////////////////////////////////////////////////////
  // Login / Logout
  ///////////////////////////////////////////////////////////////////////

  // login()
  // logout()

  ///////////////////////////////////////////////////////////////////////
  // Markets & Langs
  ///////////////////////////////////////////////////////////////////////

  setUserMarketAndLang(param: string): void {
    this._marketLang = param;

    const defaultParam = param?.split('-');
    const market = defaultParam.length ? defaultParam[0] : null;
    const lang = defaultParam.length ? defaultParam[1] : null;

    if(market && !lang) {
      const partialParam = market as keyof typeof DefaultMarketLanguagesEnum;
      if(this.isValidMarket(partialParam)) {
        const fullParam = DefaultMarketLanguagesEnum[partialParam];
        this.router.navigate([fullParam]);
      } else {
        this.navigateToDefaultMarket();
      }
    }

    if(market && lang) {
      if(this.isValidMarket(market)){
        this.setUserMarket(market);
        this.setUserLang(lang);
      } else {
        this.navigateToDefaultMarket();
      }
    }
  }

  setUserMarket(market: string): void {
    this._market = market;
    this._marketChangeSubject.next(this.market)
  }

  isValidMarket(param: string): boolean {
    return Object.keys(DefaultMarketLanguagesEnum).includes(param);
  }

  navigateToDefaultMarket(): void {
    this.router.navigate(['in-en']);
  }

  setUserLang(lang: string | undefined = undefined) {
    const langsRegex = /en|fr|de|nl|it|es|vl|wl/;

    if(lang?.match(langsRegex)) {
      this._lang = lang;
      this.setTranslation();
      return;
    }

    if(this.isValidMarket(this._market)) {
      const marketParam = this._market as keyof typeof DefaultMarketLanguagesEnum;
      const defaultMarketLang = DefaultMarketLanguagesEnum[marketParam];
      this.router.navigate([defaultMarketLang]);
    } else {
      this.navigateToDefaultMarket();
    }

    // if(this.lang) {
    //   this.setTranslation();
    //   return;
    // }

    // const browserLang = this.translate.getBrowserLang();
    // if(browserLang.match(langsRegex)) {
    //   this._lang = browserLang;
    //   this.setTranslation();
    //   return;
    // }

    // this._lang = 'en';
    // this.setTranslation();
  }

  setTranslation(): void {
    this.translate.currentLang = '';
    this.translate.use(this.lang);
    this.adapter.setLocale(this.lang);
    localStorage.setItem(GV_LANG, this._lang);

    this._langChangeSubject.next(this.lang);
  }

}
