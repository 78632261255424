import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { LayoutHeaderComponent } from './header/layout-header.component';
import { LayoutFooterComponent } from './footer/layout-footer.component';
import { RouterModule } from '@angular/router';
import { LayoutSymbolsComponent } from './layout-symbols/layout-symbols.component';
import { TranslateModule } from '@ngx-translate/core';
import { LayoutRoutingModule } from './layout-routing.module';
import { MatButtonModule } from '@angular/material/button';
import { BankCardCanvasComponent } from '@shared/components/bank-card-canvas/bank-card-canvas.component';
import { StickyBarComponent } from '@shared/components/sticky-bar/sticky-bar.component';
import { LoaderComponent } from '@core/components/loader/loader.component';
import { ContactSettingModule } from '@modules/contact-setting/contact-setting.module';

@NgModule({
  declarations: [
    LayoutComponent,
    LayoutHeaderComponent,
    LayoutFooterComponent,
    LayoutSymbolsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    LayoutRoutingModule,
    TranslateModule,
    MatButtonModule,
    BankCardCanvasComponent,
    StickyBarComponent,
    LoaderComponent,
    ContactSettingModule,
  ]
})
export class LayoutModule { }
