<footer class="footer-main">
  <section class="footer-container">
    <div class="footer-inner d-block d-lg-flex align-items-center justify-content-between">
      <div class="footer-secure-payment d-flex flex-column align-items-start justify-content-start flex-md-row align-items-md-center">
        <p class="label mb-0 d-flex align-items-center">
          <svg><use href="#icon-lock-fill"/></svg>
          {{ 'SCREEN_GIFTVOUCHER_COMMON.secure_payment' | translate }}
        </p>
        <ul class="banner-payments mb-0 p-0 d-flex align-items-center justify-content-start">
          <ng-template ngFor let-securePaymentIcon [ngForOf]="securePaymentIcons">
            <li class="banner-payment d-flex align-items-center justify-content-center">
              <img src="{{ securePaymentIcon }}">
            </li>
          </ng-template>
        </ul>
      </div>
      <ul class="footer-nav p-0 d-flex flex-column flex-md-row align-items-lg-center justify-content-start justify-content-lg-between">
        <ng-template ngFor let-link [ngForOf]="links">
          <li class="nav-item">
            <a title="{{ link.label | translate }}" target="_blank" class="nav-item-link" href="{{ link.href }}">
              {{ link.label | translate }}
            </a>
          </li>
        </ng-template>
      </ul>
      <div class="footer-copy-right">
        <p class="mb-0">© {{ theme.brandName }} {{ currentYear }}</p>
      </div>
    </div>
  </section>
</footer>
