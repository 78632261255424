import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StepRouteEnum } from '@core/enums/step-route.enum';
import { TranslateResolver } from '@core/resolver/translate.resolver';
import { LayoutComponent } from './layout.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: StepRouteEnum.Home, pathMatch: 'full' },
      {
        path: StepRouteEnum.Home,
        resolve: { translate: TranslateResolver},
        loadChildren: () => import('@modules/home/home.module').then(m => m.HomeModule),
      },
      {
        path: StepRouteEnum.ContactSetting,
        resolve: { translate: TranslateResolver },
        loadChildren: () => import('@modules/contact-setting/contact-setting.module').then(m => m.ContactSettingModule),
      },
      {
        path: StepRouteEnum.Payment,
        resolve: { translate: TranslateResolver },
        loadChildren: () => import('@modules/payment/payment.module').then(m => m.PaymentModule),
      },
      {
        path: StepRouteEnum.Confirmation,
        resolve: { translate: TranslateResolver },
        loadChildren: () => import('@modules/confirmation/confirmation.module').then(m => m.ConfirmationModule),
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutRoutingModule { }
