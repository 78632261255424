export const HOSTNAME_DEFAULT_MARKET_LANG: { [key: string]: string } = {
  'localhost': 'fr-fr',
  // PV site
  'idee-cadeau.pierreetvacances.com': 'fr-fr',
  'gift.pierreetvacances.com': 'in-en',

  // CP site
  'chequecadeau.centerparcs.fr': 'fr-fr',
  'geschenkgutsch.centerparcs.de': 'de-de',
  'cadeaubon.centerparcs.nl': 'nl-nl',
  'cadeaubon.centerparcs.be': 'be-nl',
  'chequecadeau.centerparcs.be': 'be-fr',
  'giftvoucher.centerparcs.eu': 'in-en',
};
