import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TranslatePmsLoader } from './core/services/translate-pms-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LayoutModule } from '@core/modules/layout/layout.module';
import { SnackbarModule } from '@core/modules/snackbar/snackbar.module';
import { EnsureHttpsInterceptor } from '@core/interceptors/ensure-https.interceptor';
import { LoaderInterceptor } from '@core/interceptors/loader.interceptor';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { CUSTOM_MOMENT_DP_FORMATS } from '@core/consts/moment-dp-formats';

import { MatButtonModule } from '@angular/material/button';
import { DatePipe } from '@angular/common';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { THEMES } from '@core/services/theme/theme.const';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslatePmsLoader(
    http,
    [
      'SCREEN_GIFTVOUCHER_COMMON',
      'SCREEN_GIFTVOUCHER_LAYOUT',
      'SCREEN_GIFTVOUCHER_SNACKBAR',
    ],
    environment.api.urlWording
  );
}

let gtmID = '';
let currentBrand = '';
const currentHost = window.location.hostname;
const theme = THEMES.find((theme: any) => theme.host.includes(currentHost) || currentHost.includes(theme.partialHost));
currentBrand = theme ? theme.brand : 'cp';

let currentLang = '';
const currentPath = window.location.pathname;
const _split = currentPath.split("/");
if (!!_split[1]) {
  currentLang = _split[1].split('-')[1];
} else {
  currentLang = 'en';
}

const envGtmId: {[key: string]: {[key: string]: string }} = environment.gtmId;
const _gtmID = envGtmId[currentBrand][currentLang];
gtmID = _gtmID ?? envGtmId[currentBrand]["en"];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false,
    }),
    LayoutModule,
    SnackbarModule,
    MatButtonModule,
    GoogleTagManagerModule.forRoot({
      id: gtmID,
    })
  ],
  providers: [
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: EnsureHttpsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_DP_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
