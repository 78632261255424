import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit {
  
  message: string;
  type: string;
  lg = false;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    this.message = this.data.message;
    this.type = this.data.type ? this.data.type : 'success';
    this.lg = this.data.lg ? this.data.lg : false;
  }

  close() {
    this.data.snackBar.dismiss();
  }

}
