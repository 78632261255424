import { Component } from '@angular/core';
import { ThemeService } from './core/services/theme/theme.service';
import { NavigationEnd, Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { filter } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    public themeService: ThemeService,
    readonly router: Router,
    readonly viewportScroller: ViewportScroller,
  ) {}

  ngOnInit(): void {
    this.themeService.loadTheme();
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((_: any) => {
        this.viewportScroller.scrollToPosition([0, 0]);
      });
    console.log('app loaded');
  }


}
