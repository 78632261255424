// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const host = 'https://api-dev.guestup.com';

export const environment = {
  production: false,
  env: 'DEV',
  api: {
    urlWording: `${host}/wordings/v1`,
    giftVoucher: `${host}/go-onsite-giftvoucher/v1`,
  },
  adyen: {
    environment : 'test',
    client_key: 'test_LI647MQG5ZANJDR7TV5LOEXZHU77B75R',
  },
  gtmId: {
    pv: {
      en: 'GTM-NWV6VZ9H',
      fr: 'GTM-PPK9VS8N',
    },
    cp: {
      en: 'GTM-TNLZLTMT',
      fr: 'GTM-WGQZL4JP',
      de: 'GTM-KTRWV94Q',
      nl: 'GTM-WWCWN6PR'
    },
    sp: {
      en: 'GTM-TNLZLTMT',
      fr: 'GTM-WGQZL4JP',
      de: 'GTM-KTRWV94Q',
      nl: 'GTM-WWCWN6PR'
    }
  }
};
