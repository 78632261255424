import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StepRouteEnum } from '@core/enums/step-route.enum';
import { ContactService } from '@core/services/contact.service';
import { GiftVoucherService } from '@core/services/gift-voucher.service';
import { StepRouteService } from '@core/services/step-route.service';
import { Theme } from '@core/services/theme/theme.model';
import { ThemeService } from '@core/services/theme/theme.service';
import { UserService } from '@core/services/user.service';
import { BankCardCanvasComponent } from '@shared/components/bank-card-canvas/bank-card-canvas.component';
import { LayoutService } from '../layout.service';

@Component({
  selector: 'app-layout-header',
  templateUrl: './layout-header.component.html',
  styleUrls: ['./layout-header.component.scss']
})
export class LayoutHeaderComponent implements OnInit {

  @ViewChild(BankCardCanvasComponent) bankCardCanvas: BankCardCanvasComponent;

  theme: Theme = null;
  lang: string = null;
  stepRouteEnum =  StepRouteEnum;

  constructor(
    private themeService: ThemeService,
    private userService: UserService,
    private giftVoucerService: GiftVoucherService,
    private contactService: ContactService,
    public stepRouteService: StepRouteService,
    public layoutSerivce: LayoutService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.getThemeInfos();
  }

  getThemeInfos(): void {
    this.theme = this.themeService.theme;
  }

  get homeUrl(): string {
    return `/${this.userService.market}-${this.userService.lang}/`;
  }

  get locale(): string {
    return this.userService.locale;
  }

  get voucherAmount(): number {
    return (this.giftVoucerService.voucherValue ?? 0) * (this.giftVoucerService.voucherQuantity ?? 1);
  }

  get gvCurrency(): string {
    return this.giftVoucerService.currency ?? 'EUR';
  }

  get isStepOneCompleted(): boolean {
    return this.voucherAmount > 0 && !this.stepRouteService.isHomeScreen;
  }

  get isStepTwoCompleted(): boolean {
    return this.contactService.email && !this.stepRouteService.isHomeScreen && !this.stepRouteService.isContactSettingScreen;
  }

  navigateToStep(step: StepRouteEnum, isCompleted: boolean) {
    if (isCompleted) {
      this.router.navigate([step], { relativeTo: this.route.parent });
    }
  }

  openBankCard(): void {
    this.bankCardCanvas.toggle();
  }
}
