<header id="header" class="voucher-header" [ngClass]="{'no-box-shadow': stepRouteService.isConfirmationScreen}">
  <div *ngIf="stepRouteService.isConfirmationScreen || !stepRouteService.isStepRouteScreen" class="header-logo-container px-3 px-sm-4 d-flex align-items-center" [ngClass]="{'cp-confirmation-screen': theme.brand === 'cp' && stepRouteService.isConfirmationScreen}">
    <a [routerLink]="['home']" class="logo-link">
      <img class="pv-logo-image" [attr.src]="theme.logoAlt" [attr.alt]="theme.brandName">
    </a>
  </div>
  <div *ngIf="stepRouteService.isStepRouteScreen && !stepRouteService.isConfirmationScreen" class="header-container d-flex align-items-center position-relative">
    <div class="header-logo-container d-flex align-items-center">
      <a
        [routerLink]="['home']"
        [ngClass]="{ 'ps-4': theme.brand === 'sp' }"
        class="logo-link"
      >
        <img class="pv-logo-image-layout" [attr.src]="theme.logoAlt" [attr.alt]="theme.brandName">
        <svg [class]="'mobile-logo-' + theme.brand"><use [attr.href]="'#' + theme.logoMobile"/></svg>
      </a>
    </div>
    <ng-container *ngIf="layoutSerivce.isSiteReady" [ngSwitch]="theme.name">
      <ng-container *ngSwitchCase="'theme-pv'">
        <ng-container *ngTemplateOutlet="pvNavigationHeader"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'theme-cp'">
        <ng-container *ngTemplateOutlet="cpNavigationHeader"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'theme-sp'">
        <ng-container *ngTemplateOutlet="cpNavigationHeader"></ng-container>
      </ng-container>
    </ng-container>
    <div class="header-right position-absolute d-none top-0 end-0" *ngIf="voucherAmount">
      <div class="header-right-container d-flex flex-column">
        <a class="voucher-amount-container d-flex align-items-center text-decoration-none" (click)="openBankCard()">
          <span class="voucher-amount d-inline-block position-relative align-middle text-end">
            <span class="label d-block">Total TTP</span>
            <span class="value">{{ voucherAmount | currency:gvCurrency:'symbol':'0.0-0':locale }}</span>
          </span>
          <span class="voucher-dropdown">
            <svg><use href="#icon-arrow-drop-down-line" /></svg>
          </span>
        </a>
      </div>
    </div>
  </div>
</header>

<ng-container>
  <app-bank-card-canvas #bankCardCanvas></app-bank-card-canvas>
</ng-container>

<ng-template #pvNavigationHeader>
  <div class="header-navigation w-100">
    <ul class="d-table p-0 my-0 mx-auto">
      <li class="navigation-li position-relative d-table-cell text-center" [ngClass]="{ 'active': stepRouteService.isHomeScreen }">
        <a class="navigation-link d-block text-decoration-none">
          <span class="navigation-link-picto d-inline-block">
            <svg><use href="#icon-gift-line" /></svg>
          </span>
          <p class="navigation-title">
            {{ ('SCREEN_GIFTVOUCHER_COMMON.gift_voucher_' + theme.brand) | translate }}
          </p>
        </a>
        <span class="navigation-dash position-absolute after"></span>
      </li>
      <li class="navigation-li position-relative d-table-cell text-center" [ngClass]="{ 'active': stepRouteService.isContactSettingScreen }">
        <span class="navigation-dash position-absolute before"></span>
        <a class="navigation-link d-block text-decoration-none">
          <span class="navigation-link-picto d-inline-block">
            <svg><use href="#icon-user-setting-line" /></svg>
          </span>
          <p class="navigation-title">
            {{ 'SCREEN_GIFTVOUCHER_COMMON.contact' | translate }}
          </p>
        </a>
        <span class="navigation-dash position-absolute after"></span>
      </li>
      <li class="navigation-li position-relative d-table-cell text-center" [ngClass]="{ 'active': stepRouteService.isPaymentScreen }">
        <span class="navigation-dash position-absolute before"></span>
        <a class="navigation-link d-block text-decoration-none">
          <span class="navigation-link-picto d-inline-block">
            <svg><use href="#icon-bank-card-line" /></svg>
          </span>
          <p class="navigation-title">
            {{ 'SCREEN_GIFTVOUCHER_COMMON.payment' | translate }}
          </p>
        </a>
      </li>
    </ul>
    <ul class="navigation-mobile p-0">
      <li class="navigation-mobile-li" [ngClass]="{ 'active': stepRouteService.isHomeScreen }">
        <p class="navigation-mobile-title text-center">
          {{ ('SCREEN_GIFTVOUCHER_COMMON.gift_voucher_' + theme.brand) | translate }}
        </p>
      </li>
      <li class="navigation-mobile-li" [ngClass]="{ 'active': stepRouteService.isContactSettingScreen }">
        <p class="navigation-mobile-title text-center">
          {{ 'SCREEN_GIFTVOUCHER_COMMON.contact' | translate }}
        </p>
      </li>
      <li class="navigation-mobile-li" [ngClass]="{ 'active': stepRouteService.isPaymentScreen }">
        <p class="navigation-mobile-title text-center">
          {{ 'SCREEN_GIFTVOUCHER_COMMON.payment' | translate }}
        </p>
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #cpNavigationHeader>
  <div class="cp-header-navigation d-flex align-items-center">
    <span
      class="header-item d-flex align-items-center position-relative"
      [ngClass]="{ 'active': stepRouteService.isHomeScreen, 'completed': isStepOneCompleted }"
      (click)="navigateToStep(stepRouteEnum.Home, isStepOneCompleted)"
    >
      <span class="item-icon" *ngIf="!isStepOneCompleted; else completedIcon">
        <svg><use href="#icon-cadeau" /></svg>
      </span>
      <div class="item-label fw-medium p-2">
        {{ ('SCREEN_GIFTVOUCHER_COMMON.gift_voucher_' + theme.brand) | translate }}
      </div>
      <div class="item-separator"></div>
    </span>
    <span
      class="header-item d-flex align-items-center position-relative"
      [ngClass]="{ 'active': stepRouteService.isContactSettingScreen, 'completed': isStepTwoCompleted }"
      (click)="navigateToStep(stepRouteEnum.ContactSetting, isStepTwoCompleted)"
    >
      <span class="item-icon" *ngIf="!isStepTwoCompleted; else completedIcon">
        <svg><use href="#icon-bulle-coordonnees" /></svg>
      </span>
      <div class="item-label fw-medium p-2">
        {{ 'SCREEN_GIFTVOUCHER_COMMON.contact' | translate }}
      </div>
      <div class="item-separator"></div>
    </span>
    <span class="header-item d-flex align-items-center position-relative" [ngClass]="{ 'active': stepRouteService.isPaymentScreen }">
      <span class="item-icon">
        <svg><use href="#icon-bulle-paiement" /></svg>
      </span>
      <div class="item-label fw-medium p-2">
        {{ 'SCREEN_GIFTVOUCHER_COMMON.payment' | translate }}
      </div>
    </span>
  </div>
</ng-template>

<ng-template #spNavigationHeader>
  <div class="cp-header-navigation d-flex align-items-center">
    <span
      class="header-item d-flex align-items-center position-relative"
      [ngClass]="{ 'active': stepRouteService.isHomeScreen, 'completed': isStepOneCompleted }"
      (click)="navigateToStep(stepRouteEnum.Home, isStepOneCompleted)"
    >
      <span class="item-icon" *ngIf="!isStepOneCompleted; else completedIcon">
        <svg><use href="#icon-cadeau" /></svg>
      </span>
      <div class="item-label fw-medium p-2">
        {{ ('SCREEN_GIFTVOUCHER_COMMON.gift_voucher_' + theme.brand) | translate }}
      </div>
      <div class="item-separator"></div>
    </span>
    <span
      class="header-item d-flex align-items-center position-relative"
      [ngClass]="{ 'active': stepRouteService.isContactSettingScreen, 'completed': isStepTwoCompleted }"
      (click)="navigateToStep(stepRouteEnum.ContactSetting, isStepTwoCompleted)"
    >
      <span class="item-icon" *ngIf="!isStepTwoCompleted; else completedIcon">
        <svg><use href="#icon-bulle-coordonnees" /></svg>
      </span>
      <div class="item-label fw-medium p-2">
        {{ 'SCREEN_GIFTVOUCHER_COMMON.contact' | translate }}
      </div>
      <div class="item-separator"></div>
    </span>
    <span class="header-item d-flex align-items-center position-relative" [ngClass]="{ 'active': stepRouteService.isPaymentScreen }">
      <span class="item-icon">
        <svg><use href="#icon-bulle-paiement" /></svg>
      </span>
      <div class="item-label fw-medium p-2">
        {{ 'SCREEN_GIFTVOUCHER_COMMON.payment' | translate }}
      </div>
    </span>
  </div>
</ng-template>

<ng-template #completedIcon>
  <span class="item-icon">
    <svg><use href="#icon-checked" /></svg>
  </span>
</ng-template>
