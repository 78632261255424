<form class="p-3" [formGroup]="form">
  <mat-checkbox formControlName="email">
    {{ "SCREEN_GIFTVOUCHER_COMMON.email" | translate }}
  </mat-checkbox>
  <mat-checkbox formControlName="phoneNumber">
    {{ "SCREEN_GIFTVOUCHER_COMMON.phone" | translate }}
  </mat-checkbox>
  <mat-checkbox formControlName="sms">
    {{ "SCREEN_GIFTVOUCHER_COMMON.sms" | translate }}
  </mat-checkbox>
</form>
