import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { CheckoutService } from './checkout.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  IS_PAID_KEY = 'IS_PAID_KEY';
  IS_PAYMENT_REFUSED_KEY = 'IS_PAYMENT_REFUSED_KEY';

  constructor(
    private http: HttpClient,
    private checkoutService: CheckoutService
  ) { }

  get isPaid(): boolean {
    const _isPaid = sessionStorage.getItem(this.IS_PAID_KEY);
    if (_isPaid) {
      return Boolean(_isPaid);
    }
    return undefined;
  }

  set isPaid(value) {
    sessionStorage.setItem(this.IS_PAID_KEY, value.toString());
  }

  get isPaymentRefused(): boolean {
    const _isPaid = sessionStorage.getItem(this.IS_PAYMENT_REFUSED_KEY);
    if (_isPaid) {
      return Boolean(_isPaid);
    }
    return undefined;
  }

  set isPaymentRefused(value) {
    sessionStorage.setItem(this.IS_PAYMENT_REFUSED_KEY, value.toString());
  }

  proceedPayment(data: any): Observable<any> {
    return this.http.post(
      `${environment.api.giftVoucher}${this.checkoutService.checkoutData.data.paymentUrl}`,
      data
    );
  }

  getPaymentDetail(uuid: string): Observable<any> {
    return this.http.get(`${environment.api.giftVoucher}/payment-detail`, {
      params: { uuid },
    });
  }
}
