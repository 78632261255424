import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CheckoutApiResponse, CommercialSetting } from '@core/models/checkout-response.model';
import { environment } from '@env/environment';
import { map, Observable, tap } from 'rxjs';
import { ContactService } from './contact.service';
import { GiftVoucherService } from './gift-voucher.service';
import { ThemeService } from './theme/theme.service';
import { UserService } from './user.service';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class CheckoutService {
  CHECKOUT_DATA_KEY = 'CHECKOUT_DATA_KEY';
  CHECKOUT_PAYLOAD_KEY = 'CHECKOUT_PAYLOAD_KEY';
  COMMERCIAL_SETTING_KEY = 'COMMERCIAL_SETTING_KEY';

  #commercialSetting: CommercialSetting = {
    email: true,
    call: true,
    sms: true,
  };

  constructor(
    private http: HttpClient,
    private themeService: ThemeService,
    private giftVoucherService: GiftVoucherService,
    private contactService: ContactService,
    private userService: UserService,
    private datePipe: DatePipe,
  ) { }

  set checkoutData(data: CheckoutApiResponse) {
    sessionStorage.setItem(this.CHECKOUT_DATA_KEY, JSON.stringify(data));
  }

  set checkoutPayload(data: any) {
    sessionStorage.setItem(this.CHECKOUT_PAYLOAD_KEY, JSON.stringify(data));
  }

  set commercialSetting(data: CommercialSetting) {
    sessionStorage.setItem(this.COMMERCIAL_SETTING_KEY, JSON.stringify(data));
  }

  get commercialSetting(): CommercialSetting {
    const _data = sessionStorage.getItem(this.COMMERCIAL_SETTING_KEY);
    if (_data) {
      return JSON.parse(_data);
    }
    return this.#commercialSetting;
  }

  get checkoutData(): CheckoutApiResponse {
    const _data = sessionStorage.getItem(this.CHECKOUT_DATA_KEY);
    if (_data) {
      return JSON.parse(_data);
    }
    return undefined;
  }

  get checkoutPayload(): any {
    const _data = sessionStorage.getItem(this.CHECKOUT_PAYLOAD_KEY);
    if (_data) {
      return JSON.parse(_data);
    }
    return undefined;
  }

  clearCheckoutData(): void {
    sessionStorage.removeItem(this.CHECKOUT_DATA_KEY);
  }

  get checkoutUUID(): string {
    if (!this.checkoutData) {
      return '';
    }
    return this.checkoutData.data.paymentUrl.split('/')[2];
  }

  get currentUrl(): string {
    return window.location.href.slice(0, window.location.href.lastIndexOf("/") + 1);
  }

  get localCode(): string {
    const { href } = window.location;

    if (href.includes('fr-fr') || href.includes('be-wl')) {
      return 'FR';
    } else if(href.includes('in-en')) {
      return 'EN';
    } else if(href.includes('de-de')) {
      return 'DE';
    } else if(href.includes('nl-nl') || href.includes('be-vl')) {
      return 'NL';
    }
    return '';
  }

  get isVoucherChanged(): boolean {
    if (!this.checkoutPayload) return true;

    if ((this.checkoutPayload.voucherValue !== Number(this.giftVoucherService.voucherValue)) || (this.checkoutPayload.voucherQuantity !==this.giftVoucherService.voucherQuantity)) {
      return true;
    }

    return false;
  }

  doCheckout(): Observable<any> {
    const { receiverName, receiverEmail } = this.giftVoucherService;
    const customer = !!receiverName
      ? {
        email: receiverEmail,
        name: receiverName,
        locale: this.localCode,
      }
      : undefined;

    const body = {
      shopper: {
        email: this.contactService.email,
        firstname: this.contactService.firstname,
        lastname: this.contactService.lastname,
        phone: this.contactService.phone,
        civility: this.contactService.gender,
        locale: this.localCode,
      },
      customer: customer,
      comment: this.giftVoucherService.message ?? '',
      sendDate: this.datePipe.transform(this.giftVoucherService.receiveDate ?? new Date(), 'YYYY-MM-ddTHH:mm:ss')+'.000Z',
      brandCode: this.themeService.theme.brand.toUpperCase(),
      countryCode: this.userService.market.toUpperCase(),
      locale: this.localCode,
      currency: this.giftVoucherService.currency,
      amount:
        this.giftVoucherService.voucherValue *
        (this.giftVoucherService.voucherQuantity ?? 1),
      voucherValue: Number(this.giftVoucherService.voucherValue),
      voucherQuantity: this.giftVoucherService.voucherQuantity ?? 1,
      voucherCampaign: this.giftVoucherService.voucherCampaign,
      confirmationUrl: `${this.currentUrl}confirmation`,
      reference: "0",
      timezoneOffset: new Date().getTimezoneOffset(),
      consents: Object.keys(this.commercialSetting).filter((key: 'email' | 'sms' | 'call' | string) => this.commercialSetting[key]).join(",")
    };
    return this.http.post(`${environment.api.giftVoucher}/checkout`, body).pipe(
      tap(() => {
        this.checkoutPayload = body;
      }),
      map(response => response)
    );
  }
}
