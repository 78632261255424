export class User {
  constructor(
    public customer: UserData = null,
    public token: string = null,
  ) {}
}

export interface UserData {
  firstName: string;
  lastName: string;
  email: string;
  initials: string;
  title: string;
}
