import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StepRouteEnum } from '@core/enums/step-route.enum';
import { GoogleClickEvent } from '@core/models/google-click-event.model';
import { GoogleViewEvent } from '@core/models/google-view-event.model';
import { CheckoutService } from '@core/services/checkout.service';
import { ContactService } from '@core/services/contact.service';
import { GiftVoucherService } from '@core/services/gift-voucher.service';
import { StepRouteService } from '@core/services/step-route.service';
import { ThemeService } from '@core/services/theme/theme.service';
import { UserService } from '@core/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { formHasError } from '@shared/helpers/helpers';
import { regexPatterns } from '@shared/validators/patterns.const';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-contact-setting',
  templateUrl: './contact-setting.component.html',
  styleUrls: ['./contact-setting.component.scss'],
})
export class ContactSettingComponent implements OnInit, OnDestroy, AfterViewInit {
  stop$: Subject<any> = new Subject<any>();

  form: UntypedFormGroup;

  constructor(
    private giftVoucherSerivce: GiftVoucherService,
    private checkoutService: CheckoutService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private contactService: ContactService,
    public themeService: ThemeService,
    public stepRouteService: StepRouteService,
    private giftVoucherService: GiftVoucherService,
    private userService: UserService,
    private gtmService: GoogleTagManagerService,
  ) {}

  ngOnInit(): void {
    this.giftVoucherSerivce.goToPreviousStep$
      .pipe(takeUntil(this.stop$))
      .subscribe((_) => {
        this.handleGoToPreviousScreen();
      });

    this.giftVoucherSerivce.goToNextStep$.pipe(takeUntil(this.stop$)).subscribe(_ => {
      this.pushContinueButtonTag();
      this.handleGoToNextScreen();
    });

    // Check Campaign
    if (!this.giftVoucherSerivce.voucherCampaign) {
      this.giftVoucherService
      .getGiftOptions()
      .pipe(takeUntil(this.stop$))
      .subscribe({
        next: (data) => {
          this.giftVoucherService.currency = data.data.gifts[0].currency;
          this.giftVoucherService.voucherCampaign = data.data.gifts[0].campaign;
        }
      });
    }

    this.createForm();
  }

  ngOnDestroy(): void {
    this.stepRouteService.formStatus = false;
    this.stop$.next(null);
    this.stop$.complete();
  }

  ngAfterViewInit(): void {
    this.pushViewTag();
  }

  pushViewTag(): void {
    const viewEvent: GoogleViewEvent = {
      event: 'page_view',
      breakpoint: '',
      currency: this.giftVoucherService.currency ?? 'EUR',
      environment: 'PROD',
      language: this.userService.lang,
      last_booking_status: '',
      page_name: 'Contact Setting',
      page_template: 'Contact Page',
      page_shrturl: this.router.url,
      site: this.themeService.theme.brandName,
      site_country: this.userService.market,
      user_id: '',
      collaboration_type: '',
    }
    this.gtmService.pushTag(viewEvent);
  }

  pushContinueButtonTag(): void {
    const clickEvent: GoogleClickEvent = {
      event: "clickEvent",
      eventCategory:  "Contact Setting",
      eventAction: 'Gift voucher contact',
      eventLabel: 'Continue',
    }
    this.gtmService.pushTag(clickEvent);
  }

  createForm(): void {
    const { gender, firstname, lastname, phone, email } = this.contactService;
    this.form = this.formBuilder.group({
      gender: [gender ?? null, Validators.required],
      firstname: [firstname ?? null, [Validators.required, Validators.pattern('[^0-9 ]+')]],
      lastname: [lastname ?? null, [Validators.required, Validators.pattern('[^0-9 ]+')]],
      phone: [phone ?? null, [Validators.required, Validators.pattern('^\\+?[0-9]{10,13}')]],
      email: [email ?? null, [Validators.required, Validators.pattern(regexPatterns.email)]],
    });

    // Form Status
    this.stepRouteService.formStatus = this.form.valid;
    this.form.statusChanges.pipe(takeUntil(this.stop$)).subscribe(status => {
      this.stepRouteService.formStatus = status === 'VALID';
    });

    this.form.get('gender').valueChanges.pipe(takeUntil(this.stop$)).subscribe(value => {
      this.contactService.gender = value;
    });

    this.form.get('firstname').valueChanges.pipe(takeUntil(this.stop$)).subscribe(value => {
      this.contactService.firstname = value;
    });

    this.form.get('lastname').valueChanges.pipe(takeUntil(this.stop$)).subscribe(value => {
      this.contactService.lastname = value;
    });

    this.form.get('phone').valueChanges.pipe(takeUntil(this.stop$)).subscribe(value => {
      this.contactService.phone = value;
    });

    this.form.get('email').valueChanges.pipe(takeUntil(this.stop$)).subscribe(value => {
      this.contactService.email = value;
    });
  }

  get firstNameControl(): UntypedFormControl | undefined {
    return this.form.get('firstname') as FormControl;
  }

  get errorFirstNameControl(): string {
    if (!this.firstNameControl || !this.firstNameControl.errors) return '';
    if (this.firstNameControl.errors['required']) {
      return this.translateService.instant('SCREEN_GIFTVOUCHER_COMMON.required');
    } else if (this.firstNameControl.errors['pattern']) {
      return this.translateService.instant('SCREEN_GIFTVOUCHER_COMMON.notAllowNumberAndSpace');
    } else {
      return '';
    }
  }

  get lastNameControl(): UntypedFormControl | undefined {
    return this.form.get('lastname') as FormControl;
  }

  get errorLastNameControl(): string {
    if (!this.lastNameControl || !this.lastNameControl.errors) return '';
    if (this.lastNameControl.errors['required']) {
      return this.translateService.instant('SCREEN_GIFTVOUCHER_COMMON.required');
    } else if (this.lastNameControl.errors['pattern']) {
      return this.translateService.instant('SCREEN_GIFTVOUCHER_COMMON.notAllowNumberAndSpace');
    } else {
      return '';
    }
  }

  get emailControl(): UntypedFormControl | undefined {
    return this.form.get('email') as FormControl;
  }

  get errorEmailControl(): string {
    if (!this.emailControl || !this.emailControl.errors) return '';
    if(this.emailControl.errors['required']) {
      return this.translateService.instant('SCREEN_GIFTVOUCHER_COMMON.required');
    } else if (this.emailControl.errors['pattern']) {
      return this.translateService.instant('SCREEN_GIFTVOUCHER_COMMON.errInvalidEmail');
    }
    return '';
  }

  handleChange(event: KeyboardEvent) {
    if (isNaN(Number(String.fromCharCode(event.keyCode))) && event.key !== '+') {
      event.preventDefault();
    }
  }

  get phoneControl(): UntypedFormControl | undefined {
    return this.form.get('phone') as FormControl;
  }

  get errorPhoneControl(): string {
    if (!this.phoneControl || !this.phoneControl.errors) return '';
    if(this.phoneControl.errors['required']) {
      return this.translateService.instant('SCREEN_GIFTVOUCHER_COMMON.required');
    } else if (this.phoneControl.errors['pattern']) {
      return this.translateService.instant('SCREEN_GIFTVOUCHER_COMMON.errInvalidPhone');
    }
    return '';
  }

  handleGoToPreviousScreen(): void {
    this.router.navigate(['..', StepRouteEnum.Home], { relativeTo: this.route.parent });
  }

  handleGoToNextScreen(): void {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      // Form Mobile Screen
      setTimeout(() => {
        document.querySelector(".mat-mdc-form-field-error").scrollIntoView({ block: 'center' });
      },0);
      return;
    };

    // Check if gift-voucher setting completed
    if (!this.giftVoucherSerivce.voucherCampaign || !this.giftVoucherSerivce.voucherValue) {
      this.handleGoToPreviousScreen();
      return;
    }

    if (!this.checkoutService.checkoutData || this.checkoutService.isVoucherChanged) {
      this.checkoutService.doCheckout().pipe(takeUntil(this.stop$)).subscribe(data => {
        this.checkoutService.checkoutData = data;
        this.router.navigate(['..', StepRouteEnum.Payment], { relativeTo: this.route.parent });
      });
    } else {
      this.router.navigate(['..', StepRouteEnum.Payment], { relativeTo: this.route.parent });
    }
  }

  _formHasError(form: FormGroup, controlName: string, errorName: string | null = null): boolean {
    return formHasError(form, controlName, errorName);
  }
}
