import { Component, OnInit } from '@angular/core';
import { Theme } from '@core/services/theme/theme.model';
import { ThemeService } from '@core/services/theme/theme.service';
import { Link } from '@shared/models/link';

@Component({
  selector: 'app-layout-footer',
  templateUrl: './layout-footer.component.html',
  styleUrls: ['./layout-footer.component.scss']
})

export class LayoutFooterComponent implements OnInit {

  links: Link[];
  securePaymentIcons: string[];
  currentYear: number;
  theme: Theme = null;

  constructor(
    public themeService: ThemeService,
  ) { }

  ngOnInit(): void {
    this.getThemeInfos();
    this.getCurrentYear();
    this.getFooterLinks();
    this.getSecurePaymentIcons();
  }

  getFooterLinks(): void {
    this.links = this.themeService.footerLinks;
  }

  getCurrentYear(): void {
    this.currentYear = new Date().getFullYear();
  }

  getThemeInfos(): void {
    this.theme = this.themeService.theme;
  }

  getSecurePaymentIcons(): void {
    this.securePaymentIcons = this.themeService.theme.footerSecurePaymentIcons;
  }
}
