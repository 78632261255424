import { Link } from "src/app/shared/models/link";

export const FOOTER_LINKS_CP: { [key: string]: Link[] } = {
  "fr-fr": [
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.legal",
      href: "https://www.centerparcs.fr/fr-fr/mentions-legales_ms"
    },
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.personal_data_cookies",
      href: "https://www.centerparcs.fr/fr-fr/politique-protection-donnees-cookies_ms"
    },
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.cgv",
      href: "https://www.centerparcs.fr/fr-fr/conditions-generales-vente_ms"
    },
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.cgu",
      href: "https://www.centerparcs.fr/fr-fr/conditions-generales-utilisation_ms"
    },
  ],
  "in-en": [
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.legal",
      href: "https://www.centerparcs.eu/in-en/privacy-statement_ms"
    },
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.personal_data_cookies",
      href: "https://www.centerparcs.eu/in-en/cookies-and-data-protection-policy_ms"
    },
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.cgv",
      href: "https://www.centerparcs.eu/in-en/general-terms-sale_ms"
    },
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.cgu",
      href: "https://www.centerparcs.eu/in-en/general-terms-use_ms"
    },
  ],
  "de-de": [
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.personal_data_cookies",
      href: "https://www.centerparcs.de/de-de/datenschutz_ms"
    },
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.cgv",
      href: "https://www.centerparcs.de/de-de/allgemeine-geschaftsbedingungen_ms"
    },
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.cgu",
      href: "https://www.centerparcs.de/de-de/impressum_ms"
    },
  ],
  "nl-nl": [
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.legal",
      href: "https://www.centerparcs.nl/nl-nl/bedrijfsinformatie_ms"
    },
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.personal_data_cookies",
      href: "https://www.centerparcs.nl/nl-nl/beleid-persoonsgegevensbescherming-cookies_ms"
    },
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.cgv",
      href: "https://www.centerparcs.nl/nl-nl/algemene-verkoopvoorwaarden_ms"
    },
  ],
  "be-vl": [
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.legal",
      href: "https://www.centerparcs.be/be-vl/bedrijfsinformatie_ms"
    },
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.personal_data_cookies",
      href: "https://www.centerparcs.be/be-vl/beleid-persoonsgegevensbescherming-cookies_ms"
    },
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.cgv",
      href: "https://www.centerparcs.be/be-vl/algemene-verkoopvoorwaarden_ms"
    },
  ],
  "be-wl": [
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.legal",
      href: "https://www.centerparcs.be/be-wl/mentions-legales_ms"
    },
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.personal_data_cookies",
      href: "https://www.centerparcs.be/be-wl/politique-protection-donnees-cookies_ms"
    },
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.cgv",
      href: "https://www.centerparcs.be/be-wl/cgv_ms"
    },
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.cgu",
      href: "https://www.centerparcs.be/be-wl/conditions-generales-utilisation_ms"
    },
  ],
};

export const FOOTER_LINKS_SP: { [key: string]: Link[] } = {
  "fr-fr": [
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.legal",
      href: "https://www.sunparks.com/fr-fr/mentions-legales_ms"
    },
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.personal_data_cookies",
      href: "https://www.sunparks.com/fr-fr/data-protection_ms"
    },
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.cgv",
      href: "https://www.sunparks.com/fr-fr/cgv_ms"
    },
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.cgu",
      href: "https://www.sunparks.com/fr-fr/cgu_ms"
    },
  ],
  "de-de": [
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.personal_data_cookies",
      href: "https://www.sunparks.com/de-de/datenschutz_ms"
    },
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.cgv",
      href: "https://www.sunparks.com/de-de/cgv_ms"
    },
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.cgu",
      href: "https://www.sunparks.com/de-de/cgu_ms"
    },
  ],
  "nl-nl": [
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.legal",
      href: "https://www.sunparks.com/nl-nl/algemene-verkoopsvoorwaarden_ms"
    },
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.personal_data_cookies",
      href: "https://www.sunparks.com/nl-nl/beleid-persoonsgegevensbescherming-cookies_ms"
    },
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.cgv",
      href: "https://www.sunparks.com/nl-nl/cgv_ms"
    },
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.cgu",
      href: "https://www.sunparks.com/nl-nl/cgu_ms"
    },
  ],
  "be-vl": [
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.legal",
      href: "https://www.sunparks.com/be-vl/algemene-verkoopsvoorwaarden_ms"
    },
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.personal_data_cookies",
      href: "https://www.sunparks.com/be-vl/beleid-persoonsgegevensbescherming-cookies_ms"
    },
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.cgv",
      href: "https://www.sunparks.com/be-vl/cgv_ms"
    },
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.cgu",
      href: "https://www.sunparks.com/be-vl/cgu_ms"
    }
  ],
  "be-wl": [
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.legal",
      href: "https://www.sunparks.com/be-wl/mentions-legales_ms"
    },
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.personal_data_cookies",
      href: "https://www.sunparks.com/be-wl/data-protection_ms"
    },
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.cgv",
      href: "https://www.sunparks.com/be-wl/cgv_ms"
    },
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.cgu",
      href: "https://www.sunparks.com/be-wl/cgu_ms"
    },
  ],
};

export const FOOTER_LINKS_PV: { [key: string]: Link[] } = {
  "fr-fr": [
    {
      label: 'SCREEN_GIFTVOUCHER_COMMON.legal',
      href: 'https://www.pierreetvacances.com/fr-fr/mentions_ms?pl=footer'
    },
    {
      label: 'SCREEN_GIFTVOUCHER_COMMON.personal_data_cookies',
      href: 'https://www.pierreetvacances.com/fr-fr/mentions_ms?pl=footer'
    },
    {
      label: 'SCREEN_GIFTVOUCHER_COMMON.cgv',
      href: 'https://www.pierreetvacances.com/fr-fr/cgv_ms?pl=footer'
    },
  ],
  "in-en": [
    {
      label: 'SCREEN_GIFTVOUCHER_COMMON.legal',
      href: 'https://www.pierreetvacances.com/GB-EN/disclaimer_ms'
    },
    {
      label: 'SCREEN_GIFTVOUCHER_COMMON.personal_data_cookies',
      href: 'https://www.pierreetvacances.com/GB-EN/cookies-and-data-protection-policy_ms'
    },
    {
      label: 'SCREEN_GIFTVOUCHER_COMMON.cgv',
      href: 'https://www.pierreetvacances.com/GB-EN/general-terms-use_ms'
    },
  ]
}

export const FOOTER_SECURE_PAYMENT_PV: string[] = [
  '/assets/img/payment/CB.png',
  '/assets/img/payment/mastercard.png',
  '/assets/img/payment/visa.png',
  // '/assets/img/payment/am.png',
  // '/assets/img/payment/paypal.png',
  // '/assets/img/payment/ancv.png',
]

export const FOOTER_SECURE_PAYMENT_CP: string[] = [
  '/assets/img/payment/CB.png',
  '/assets/img/payment/mastercard.png',
  '/assets/img/payment/visa.png',
]

export const FOOTER_SECURE_PAYMENT_SP: string[] = [
  '/assets/img/payment/CB.png',
  '/assets/img/payment/mastercard.png',
  '/assets/img/payment/visa.png',
]
