import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  isEmbed = false;

  // Info Page
  isSiteReady = true;

  constructor(
    private route: ActivatedRoute,
  ) { }

  setEmbedMode(): void {
    const embed = this.route.snapshot.queryParamMap.get('embed');
    if(embed != undefined) {
      this.isEmbed = embed === 'false' ? false : true;
    }
  }
}
