<div #overlay id="overlay" class="d-none position-fixed w-100 h-100 top-0 bottom-0 start-0 end-0" [ngClass]="{ 'open': isOpen }" (click)="toggle()">
  <div class="bank-card-wrapper d-flex flex-column w-100" (click)="handleWrapperClick($event)">
      <svg class="close-icon" (click)="toggle()"><use href="#icon-arrow-drop-down-s-line" /></svg>
      <div class="voucher-card-wrapper w-100 position-relative">
        <img class="voucher-card w-100" src="{{theme.card}}" alt="Voucher Card">
        <div class="voucher-amount position-absolute">
          <p class="d-flex m-0">
            <span *ngIf="voucherValue && locale === 'en'">€</span>
            {{ voucherValue ? voucherValue * (voucherQuantity ?? 1) : '' }}
            <span *ngIf="voucherValue && locale === 'fr'">€</span>
          </p>
          <p class="voucher-amount-detail d-flex m-0" *ngIf="voucherQuantity && voucherValue"><span>{{ voucherQuantity }}</span>&nbsp;x <span *ngIf="locale === 'en'">€</span>{{ voucherValue }}<span *ngIf="locale === 'fr'">€</span></p>
        </div>
      </div>
      <div *ngIf="hasReceiver && !stepRouteService.isHomeScreen" class="voucher-receiver-information d-flex flex-column gap-2 p-3">
        <div class="info-wrapper d-flex gap-2">
          <p class="label m-0">{{ 'SCREEN_GIFTVOUCHER_COMMON.from' | translate }} : </p>
          <p class="value m-0">{{ buyerName || "" }}</p>
        </div>
        <div class="info-wrapper d-flex gap-2">
          <p class="label m-0">{{ 'SCREEN_GIFTVOUCHER_COMMON.for' | translate }} : </p>
          <p class="value m-0" [title]="receiverName">{{ receiverName || "" }}</p>
        </div>
        <div class="info-wrapper d-flex gap-2">
          <p class="label m-0">{{ 'SCREEN_GIFTVOUCHER_COMMON.recipient_email' | translate }} :  </p>
          <p class="value m-0" [title]="receiverEmail">{{ receiverEmail || "" }}</p>
        </div>
        <div class="info-wrapper d-flex gap-2">
          <p class="label m-0">{{ 'SCREEN_GIFTVOUCHER_COMMON.send_date' | translate }} : </p>
          <p class="value m-0">{{ receiveDate && hasReceiver ? (receiveDate | date:'dd/MM/yyyy') : "" }}</p>
        </div>
      </div>
      <div *ngIf="!hasReceiver && hasBuyerInfo && !stepRouteService.isHomeScreen" class="voucher-receiver-information d-flex flex-column gap-2 p-3">
        <div class="info-wrapper d-flex gap-2">
          <p class="label m-0">{{ 'SCREEN_GIFTVOUCHER_COMMON.firstname' | translate }} : </p>
          <p class="value m-0" [title]="buyerFirstname">{{ buyerFirstname || "" }}</p>
        </div>
        <div class="info-wrapper d-flex gap-2">
          <p class="label m-0">{{ 'SCREEN_GIFTVOUCHER_COMMON.lastname' | translate }} : </p>
          <p class="value m-0" [title]="buyerLastname">{{ buyerLastname || "" }}</p>
        </div>
        <div class="info-wrapper d-flex gap-2">
          <p class="label m-0">{{ 'SCREEN_GIFTVOUCHER_COMMON.email' | translate }} : </p>
          <p class="value m-0" [title]="buyerEmail">{{ buyerEmail || "" }}</p>
        </div>
      </div>
  </div>
</div>
