export const CUSTOM_MOMENT_DP_FORMATS = {
  parse: {
      dateInput: 'L',
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMM YYYY',
    // monthLabel: 'MMMM',
  },
};