import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { CheckoutService } from "@core/services/checkout.service";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: 'app-commerial-form',
  templateUrl: './commercial-form.component.html',
  styleUrls: ['./commercial-form.component.scss'],
})
export class CommercialFormComponent implements OnInit, OnDestroy {
  stop$: Subject<any> = new Subject<any>();

  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private checkoutService: CheckoutService,
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.stop$.next(null);
    this.stop$.complete();
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      email: [this.checkoutService.commercialSetting['email']],
      phoneNumber: [this.checkoutService.commercialSetting['call']],
      sms: [this.checkoutService.commercialSetting['sms']],
    });

    this.form.valueChanges.pipe(takeUntil(this.stop$)).subscribe((value) => {
      this.checkoutService.commercialSetting = {
        email: value.email,
        call: value.phoneNumber,
        sms: value.sms,
      };
    });
  }
}
