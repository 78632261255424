<form *ngIf="form" [formGroup]="form">
  <h4 class="title mb-4">{{ 'SCREEN_GIFTVOUCHER_COMMON.contact_detail_title' | translate }}</h4>
  <div class="contact-details-form-wrapper mb-4 mb-md-0 px-3 py-4">
    <div class="contact-details-content d-flex flex-column gap-3 my-0 mx-auto px-3 px-md-0">
      <ng-container [ngSwitch]="themeService.theme.name">
        <div *ngSwitchCase="'theme-pv'" class="d-flex flex-column flex-wrap gap-1 gap-md-6">
          <mat-radio-group formControlName="gender" aria-label="Select a gender">
            <mat-radio-button value="Mrs">{{ 'SCREEN_GIFTVOUCHER_COMMON.female' | translate }}</mat-radio-button>
            <mat-radio-button value="Mr">{{ 'SCREEN_GIFTVOUCHER_COMMON.male' | translate }}</mat-radio-button>
          </mat-radio-group>
          <mat-error *ngIf="_formHasError(form, 'gender', 'required')">{{ 'SCREEN_GIFTVOUCHER_COMMON.required' | translate }}</mat-error>
        </div>
        <div *ngSwitchCase="'theme-cp'" class="d-flex flex-column gap-1">
          <mat-label>{{ 'SCREEN_GIFTVOUCHER_COMMON.your_civility' | translate }}</mat-label>
          <mat-button-toggle-group class="cp-gender-select" name="gender-select" formControlName="gender" aria-label="Select a gender">
            <mat-button-toggle value="Mrs">{{ 'SCREEN_GIFTVOUCHER_COMMON.female' | translate }}</mat-button-toggle>
            <mat-button-toggle value="Mr">{{ 'SCREEN_GIFTVOUCHER_COMMON.male' | translate }}</mat-button-toggle>
          </mat-button-toggle-group>
          <mat-error *ngIf="_formHasError(form, 'gender', 'required')">{{ 'SCREEN_GIFTVOUCHER_COMMON.required' | translate }}</mat-error>
        </div>
      </ng-container>
      <div class="d-flex flex-column flex-1 gap-3 flex-md-row">
        <div class="d-flex flex-column flex-grow-1 gap-1">
          <mat-label>{{ 'SCREEN_GIFTVOUCHER_COMMON.firstname' | translate }}</mat-label>
          <mat-form-field appearance="outline">
            <input matInput formControlName="firstname" />
            <mat-error *ngIf="!!errorFirstNameControl">{{ errorFirstNameControl }}</mat-error>
          </mat-form-field>
        </div>
        <div class="d-flex flex-column flex-grow-1 gap-1">
          <mat-label>{{ 'SCREEN_GIFTVOUCHER_COMMON.lastname' | translate }}</mat-label>
          <mat-form-field appearance="outline">
            <input matInput formControlName="lastname" />
            <mat-error *ngIf="!!errorLastNameControl">{{ errorLastNameControl }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="d-flex flex-column flex-grow-1 gap-1">
        <mat-label>{{ 'SCREEN_GIFTVOUCHER_COMMON.phone' | translate }}</mat-label>
        <mat-form-field appearance="outline">
          <input matInput type="tel" (keypress)="handleChange($event)"
          (keyup)="handleChange($event)" formControlName="phone" />
          <mat-error *ngIf="!!errorPhoneControl">{{ errorPhoneControl }}</mat-error>
        </mat-form-field>
      </div>
      <div class="d-flex flex-column gap-1">
        <mat-label>{{ 'SCREEN_GIFTVOUCHER_COMMON.email' | translate }}</mat-label>
        <mat-form-field appearance="outline">
          <input matInput formControlName="email" />
          <mat-error *ngIf="!!errorEmailControl">{{ errorEmailControl }}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
