<div class="snackbar-content d-flex align-items-center py-2">

  <div class="d-flex align-items-center fw-bold me-2">
    <ng-container *ngIf="type === 'success'">
      <svg class="icon-26 me-2"><use href="#icon-check-circle" /></svg>
      {{ 'SCREEN_GIFTVOUCHER_COMMON.success' | translate }}
    </ng-container>
    <ng-container *ngIf="type === 'warning'">
      <svg class="icon-26 me-2"><use href="#icon-warning" /></svg>
      {{ 'SCREEN_GIFTVOUCHER_COMMON.warning' | translate }}
    </ng-container>
    <ng-container *ngIf="type === 'error'">
      <svg class="icon-26 me-2"><use href="#icon-close-circle" /></svg>
      {{ 'SCREEN_GIFTVOUCHER_COMMON.error' | translate }}
    </ng-container>
    <ng-container *ngIf="type === 'info'">
      <svg class="icon-26 me-2"><use href="#icon-info" /></svg>
      {{ 'SCREEN_GIFTVOUCHER_COMMON.info' | translate }}
    </ng-container>
  </div>

  {{message | translate}}

  <div class="close-snackbar text-primary" (click)="close()">
    <svg class="icon"><use href="#icon-close" /></svg>
  </div>
</div>
